<template>
<!-- 退款管理 -->
  <div class="box100 ">
    <div class="whiteBg" v-if="!showInfo">
      <div class="flexAC" style="margin-bottom: 16px;">
        <el-input v-model="orderNo"  placeholder="请输入订单号" class="inputSearch"></el-input>
        <el-select v-model="refundVal" placeholder="退款状态" class="retSelect">
            <el-option v-for="(item, index) in refundOption" :key="index" :label="item.name" :value="item.value"></el-option>
        </el-select>
        <el-button class="btnSearch" @click="handleSearch">搜索</el-button>
        <el-button class="btnReset" @click="handleReset">重置</el-button>
      </div>
      <div class="table_list">
        <el-table
          :data="refundList"
          :header-cell-style="{background:'#EBF2FE'}">
          <el-table-column
            label="序号"
            type="index">
          </el-table-column>
          <el-table-column
            label="退款单号"
            prop="areid">
          </el-table-column>
          <el-table-column
            label="退款类型"
            prop="aretype">
            <template slot-scope="scope">
              <span v-if="scope.row.aretype === 1">全额退</span>
              <span v-if="scope.row.aretype === 2">不全额退</span>
            </template>
          </el-table-column>
          <el-table-column
            label="实付金额"
            prop="paymoney">
          </el-table-column>
          <el-table-column
            label="退款金额"
            prop="areamount">
          </el-table-column>
          <el-table-column
            label="申请时间"
            prop="creatime">
          </el-table-column>
          <el-table-column
            label="付款方式"
            prop="sysvalue">
          </el-table-column>
          <el-table-column
            label="退款状态"
            prop="refund_state">
          </el-table-column>
          <el-table-column
            width="150"
            label="操作">
            <template slot-scope="scope">
              <template v-if="roleBtn[0].power">
                <el-button type="text" size="small" @click="showDetail(scope.row)">查看详情</el-button>
              </template>
              <el-button type="text" size="small" v-if="scope.row.refind_isok === true" style="color: #44B557;" @click="handAgree(scope.row)">同意</el-button>
              <el-button type="text" size="small" v-if="scope.row.refind_isok === true" style="color: red;" @click="handRefuse(scope.row)">拒绝</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @current-change="pageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>
    <!-- 详情 -->
    <div class="detailForm" v-if="showInfo" style="background: transparent;padding: 0px;">
      <RefundDetail :reInfo="reInfo" @Back="Back"></RefundDetail>
    </div>
  </div>
</template>

<script>
import RefundDetail from '@/components/after/refundDetail'
import { afterRefundQuery, refundDis } from '@/api/after'

export default {
  name: 'afterRefund',
  props: ['roleBtn'],
  components: {
    RefundDetail
  },
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      orderNo: '',
      refundVal: '',
      refundOption: [
        { name: '全部', value: 0 }, { name: '待审核', value: 2 },
        { name: '已同意', value: 3 }, { name: '已拒绝', value: 4 }
      ],
      refundList: [],
      showInfo: false,
      reInfo: '',
      megasid: null, // 从消息列表跳转来的退款id
    }
  },
  created () {
    this.megasid = this.$route.params.megasid
    if (this.megasid) { // 从消息列表跳转过来
      this.showInfo = true
      const obj = {
        areid: this.megasid,
        goType: '2'
      }
      this.reInfo = obj
    } else {
      this.init()
    }
  },
  methods: {
    /* 退款申请列表 */
    init () {
      const params = {
        yrefundis: this.refundVal,
        indentid: this.orderNo,
        page_index: this.page,
        page_size: this.size
      }
      afterRefundQuery(params).then(res => {
        if (res.status === 1) {
          this.refundList = res.data.data
          this.total = res.data.recordcount
        }
      })
    },
    handleSearch () {
      this.init()
    },
    handleReset () {
      this.orderNo = ''
      this.refundVal = ''
      this.init()
    },
    // 翻页
    pageChange (page) {
      this.page = page
      this.init()
    },
    // 查看详情
    showDetail (row) {
      this.showInfo = true
      const obj = {
        areid: row.areid,
        goType: '1'
      }
      this.reInfo = obj
    },
    // 点击同意
    handAgree (row) {
      this.$confirm('确认是否[同意]当前的退款申请', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        refundDis({ areid: row.areid, yrefundis: 2 }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    // 点击拒绝
    handRefuse (row) {
      this.$confirm('确认是否[拒绝]当前的退款申请', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        refundDis({ areid: row.areid, yrefundis: 3 }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    Back () {
      this.showInfo = false
      this.$emit('handMsgBack')
    }
  }
}
</script>

<style scoped>

</style>
