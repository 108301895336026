import { post } from '@/http/http'

// 查询评论
export const afterinfoGet = p => post('api/v1.0/afterinfo/afterinfo_get', p)

// 评论添加（添加测试数据）
export const afterinfoAdd = p => post('api/v1.0/afterinfo/afterinfo_add', p)

// 编辑评论回复
export const afterinfoUpdate = p => post('api/v1.0/afterinfo/afterinfo_update', p)

// 删除评论
export const afterinfoDelete = p => post('api/v1.0/afterinfo/afterinfo_delete', p)

// 查询评论等级信息
export const afterlevelDelete = p => post('api/v1.0/afterinfo/aeinfolevel_get', p)

// 退款管理
// 退款记录查询
export const afterRefundQuery = p => post('api/v1.0/afterinfo/after_refund_get', p)

// 退款详情查询
export const refundDetail = p => post('api/v1.0/afterinfo/after_refund_details', p)

// 退款审批
export const refundDis = p => post('api/v1.0/afterinfo/after_refund_dis', p)

// 退款流程查询
export const refundProcess = p => post('api/v1.0/afterinfo/after_refund_process', p)
