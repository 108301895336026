<!-- 退款详情 -->
<template>
  <div class="box100 flexSC">
    <div class="ref_left">
      <div class="formClass">
        <div class="title flexCB">
          <p>基本信息</p>
          <p @click="back()" class="el-icon-arrow-left" style="font-size: 14px;cursor: pointer;">返回</p>
        </div>
        <template v-if="details.arebill !== 3">
          <div class="info_row flexCB">
            <p class="row_col"><span class="col_L">订单编号</span><span class="col_R">{{ info.indentid }}</span></p>
            <p class="row_col"><span class="col_L">订单状态</span><span class="col_R">{{ info.indentstate }}</span></p>
          </div>
          <div class="info_row flexCB">
            <p class="row_col"><span class="col_L">下单时间</span><span class="col_R">{{ info.indentime }}</span></p>
            <p class="row_col"><span class="col_L">销售类型</span><span class="col_R">{{ info.xs_type }}</span></p>
          </div>
          <div class="info_row flexCB">
            <p class="row_col"><span class="col_L">用户</span><span class="col_R">{{ info.username }}</span></p>
            <p class="row_col"><span class="col_L">手机号码</span><span class="col_R">{{ info.uphone }}</span></p>
          </div>
<!--          <div class="info_row flexCB">-->
<!--            <p class="row_col"><span class="col_L">美容师</span><span class="col_R">305号</span></p>-->
<!--            <p class="row_col"><span class="col_L">服务门店</span><span class="col_R">凤城五路店</span></p>-->
<!--          </div>-->
          <div class="info_row flexCB">
            <p class="row_col"><span class="col_L">付款方式</span><span class="col_R">{{ info.sysvalue }}</span></p>
            <p class="row_col"><span class="col_L">订单类型</span><span class="col_R">{{ info.indentype }}</span></p>
          </div>
          <div class="info_row flexCB">
            <p class="row_col"><span class="col_L">订单金额</span><span class="col_R">{{ info.indentmoney }}</span></p>
            <p class="row_col"><span class="col_L">优惠金额</span><span class="col_R">{{ info.prefemoney }}</span></p>
          </div>
          <div class="info_row flexCB">
            <p class="row_col"><span class="col_L">实付金额</span><span class="col_R">{{ info.paymoney }}</span></p>
          </div>
        </template>
        <template v-if="details.arebill === 3">
          <div class="info_row flexCB">
            <p class="row_col"><span class="col_L">用户</span><span class="col_R">{{ info.username }}</span></p>
            <p class="row_col"><span class="col_L">手机号码</span><span class="col_R">{{ info.uphone }}</span></p>
          </div>
        </template>
      </div>
      <div class="formClass" style="border: none;margin-bottom: 0;">
        <template>
          <div v-if="details.arebill === 1" class="title flexCB"><p>服务</p></div>
          <div v-if="details.arebill === 2" class="title flexCB"><p>套餐卡</p></div>
          <div v-if="details.arebill === 3" class="title flexCB"><p>充值</p></div>
          <el-table :data="list" show-summary>
            <el-table-column
              label="名称"
              prop="name">
            </el-table-column>
            <el-table-column
              label="数量"
              prop="num">
            </el-table-column>
            <el-table-column
              label="价格"
              prop="price">
            </el-table-column>
          </el-table>
        </template>
<!--        <template v-if="details.arebill == 2">-->
<!--          <div class="title flexCB"><p>套餐卡</p></div>-->
<!--          <div class="flexCB" style="font-size: 14px;"><p>脸部清洁年卡</p><p>13688.00</p></div>-->
<!--          <el-table :data="serveList" show-summary>-->
<!--            <el-table-column-->
<!--              label="名称"-->
<!--              prop="servename">-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--              label="数量"-->
<!--              prop="scount">-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--              label="价格"-->
<!--              prop="srental">-->
<!--            </el-table-column>-->
<!--          </el-table>-->
<!--        </template>-->
<!--        <template v-if="details.arebill == 3">-->
<!--          <div class="title flexCB"><p>充值</p></div>-->
<!--          <div class="flexCB" style="font-size: 14px;"><p>本金</p><p>13688.00</p></div>-->
<!--          <el-table :data="serveList" show-summary>-->
<!--            <el-table-column-->
<!--              label="名称"-->
<!--              prop="servename">-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--              label="数量"-->
<!--              prop="scount">-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--              label="价格"-->
<!--              prop="srental">-->
<!--            </el-table-column>-->
<!--          </el-table>-->
<!--        </template>-->
      </div>
      <div class="formClass" style="border: none;">
        <div class="title flexCB"><p>退款详情</p></div>
        <div class="refund_detail">
          <div class="info_row flexCB">
            <p class="row_col"><span class="col_L">退款单号</span><span class="col_R">{{ details.areid }}</span></p>
            <p class="row_col"><span class="col_L">退款金额</span><span class="col_R">{{ details.areamount }}</span></p>
          </div>
          <div class="info_row flexCB">
            <p class="row_col"><span class="col_L">退款时间</span><span class="col_R">{{ details.creatime }}</span></p>
            <p class="row_col"><span class="col_L">退款金额</span><span class="col_R">{{ details.aretype }}</span></p>
          </div>
          <div class="info_row flexCB">
            <p class="row_col"><span class="col_L">退款原因</span><span class="col_R">{{ details.arewhy }}</span></p>
            <p class="row_col"><span class="col_L">申请时间</span><span class="col_R">{{ details.tk_time }}</span></p>
          </div>
          <div class="info_row">
            <p class="row_col" style="display: flex;">
              <span class="col_L">备注</span>
              <span class="col_R" style="width: calc(100% - 75px);">{{ details.aremark }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="ref_right">
      <p class="title">预约进度</p>
      <el-steps direction="vertical" class="dia_step">
        <el-step class="diaStep_item" v-for="(item, index) in speedList" :key="index">
          <template slot="description">
            <p class="stepTime">{{ item.aptitle }}</p>
            <p>{{ item.apmark }}</p>
            <p>{{ item.creatime }}</p>
          </template>
        </el-step>
      </el-steps>
    </div>
  </div>
</template>

<script>
import { refundDetail, refundProcess } from '../../api/after'

export default {
  name: 'refundDetail',
  props: ['reInfo'],
  data () {
    return {
      speedList: [],
      serveList: [],
      details: '',
      info: '',
      list: []
    }
  },
  created () {
    console.log(this.reInfo)
    this.getDetail()
    this.getSpeed()
  },
  methods: {
    /* 退款详情 */
    getDetail () {
      refundDetail({ areid: this.reInfo.areid }).then(res => {
        if (res.status === 1) {
          this.details = res.data.details
          this.info = res.data.info
          this.list = res.data.list
        }
      })
    },
    /* 退款流程 */
    getSpeed () {
      refundProcess({ areid: this.reInfo.areid }).then(res => {
        if (res.status === 1) {
          this.speedList = res.data.data
        }
      })
    },
    back () { /* 1-列表 2-消息 跳转过来 */
      if (this.reInfo.goType === '1') {
        this.$emit('Back')
      } else {
        this.$emit('Back')
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style scoped>
.ref_left{
  width: calc(100% - 322px);
  height: 100%;
  background: #fff;
  position: relative;
  padding: 24px 30px;
  box-sizing: border-box;
}
.ref_right{
  width: 322px;
  background: #fff;
  margin-left: 8px;
  position: relative;
  padding: 24px 20px;
  box-sizing: border-box;
}
.title {
  font-size: 18px;
  font-family: 'PingFang SC-Bold, PingFang SC';
  font-weight: bold;
  color: #333333;
  margin-bottom: 24px;
}
.info_row{
  font-size: 14px;
  margin-top: 10px;
}
.row_col{
  min-width: 40%;
}
.col_L{
  width: 65px;
  display: inline-block;
  margin-right: 8px;
  color: #A5B7CC;
}
.col_R{
  color: #333333;
}
.diaStep_item{
  font-size: 14px;
}
.dia_step >>> .el-step__icon.is-text{
  width: 14px;
  height: 14px;
  border: 1px solid #fff;
  background: #DDDDDD;
}
.dia_step >>> .el-step__icon-inner{
  display: none;
}
.dia_step >>> .el-step__line{
  width: 1px;
  left: 7px;
  background: #DDDDDD;
}
.dia_step >>> .el-step__title{padding: 0px;}
.dia_step >>> .el-step__main{margin-bottom: 15px;}
</style>
